<template>
  <c-box>
    <c-flex
      flexWrap="wrap"
      marginTop="30px"
      mx="-10px"
      v-chakra="{
        '.disabled': {
          borderColor: '#C4C4C4',
          backgroundColor: 'superLightGray.900',
          color: 'black.900',
          cursor: 'not-allowed',
          pointerEvents: 'none',
          button: {
            borderColor: '#C4C4C4',
          },
        },
      }"
    >
      <c-form-control is-required marginBottom="30px" w="50%" px="10px">
        <c-form-label
          fontSize="14px"
          color="#555555"
          fontWeigh="400"
          fontFamily="Roboto"
        >
          Jam Mulai Makan
        </c-form-label>
        <c-input-group size="md">
          <c-input
            type="time"
            id="time1"
            height="62px"
            placeholder="Masukkan Jam Mulai Makan"
            v-model="timeStart"
            required
          />
          <c-input-right-element width="4.5rem" mt="10px">
            <c-image :src="require('@/assets/icon-jam-grey.svg')" alt="image" />
          </c-input-right-element>
        </c-input-group>
      </c-form-control>
      <c-form-control is-required marginBottom="30px" w="50%" px="10px">
        <c-form-label
          fontSize="14px"
          color="#555555"
          fontWeigh="400"
          fontFamily="Roboto"
        >
          Jam Akhir Makan
        </c-form-label>
        <c-input-group size="md">
          <c-input
            type="time"
            id="time1"
            height="62px"
            placeholder="Masukkan Jam Berhenti Makan"
            v-model="timeEnd"
            required
          />
          <c-input-right-element width="4.5rem" mt="10px">
            <c-image :src="require('@/assets/icon-jam-grey.svg')" alt="image" />
          </c-input-right-element>
        </c-input-group>
      </c-form-control>
      <c-form-control is-required marginBottom="30px" w="100%" px="10px">
        <c-form-label
          fontSize="14px"
          color="#555555"
          fontWeigh="400"
          fontFamily="Roboto"
        >
          Kebutuhan Jumlah Kalori
        </c-form-label>
        <c-input-group size="md">
          <c-select
            placeholder="Pilih Kebutuhan Jumlah Kalori"
            v-model="caloriesNeed"
            height="62px"
          >
            <option value="10">10%</option>
            <option value="15">15%</option>
            <option value="20">20%</option>
            <option value="25">25%</option>
            <option value="30">30%</option>
            <option value="35">35%</option>
            <option value="40">40%</option>
            <option value="45">45%</option>
            <option value="50">50%</option>
          </c-select>
        </c-input-group>
      </c-form-control>
      <c-form-control is-required marginBottom="30px" w="100%" px="10px">
        <c-form-label
          fontSize="14px"
          color="#555555"
          fontWeigh="400"
          fontFamily="Roboto"
        >
          Kkal - Jumlah Kalori
        </c-form-label>
        <c-input-group size="md">
          <c-input
            type="text"
            error-border-color="red.300"
            id="kKal"
            :value="jumlahKaloriPercent"
            height="62px"
            disabled
          />
        </c-input-group>
      </c-form-control>
    </c-flex>

    <c-flex p="8px" mt="5px" mb="20px" background="#F2F9F9" border-radius="8px">
      <c-image
        :src="require('@/assets/icon-info-circle-grey.svg')"
        alt="icon info"
        h="18px"
        w="18px"
      />
      <c-text ml="8px" font-size="12px" color="#555555">
        Daftar golongan makanan di bawah ini opsional.
      </c-text>
    </c-flex>

    <FormCardMakanan
      ref="cardMakananForm"
      v-for="item in meals_"
      :key="`${category}-${item._id}-${item.name}`"
      :category="category"
      :title="item.name"
      :portion="getPortionFor(item.name)"
      :value="item.items"
      @portion-changed="onPortionChangedFor(item.name, $event)"
    />
  </c-box>
</template>

<script>
import FormCardMakanan from "@/views/meal-plan/forms/form-card-makanan"
import { mapState } from "vuex"
import equal from "fast-deep-equal"
import _ from "lodash";
import greenlet from "greenlet"

let mealsDataHandler = greenlet(function _mealsDataHandler(value) {
  let val = value.val;
  let delta = value.delta;

  let result = []

  let meals = val[0]
  let glossaries = val[1]
  let valueId = val[2]

  if (glossaries == null) return result
  if (meals == null) return result
  // if (valueId == null) return result

  let data = glossaries.map((glossary) => {
    let index = meals.findIndex(
        (it) => it.foodIngredient === glossary.foodIngredient
    )

    if (index !== -1) {
      let meal = meals[index]

      let data = Object.assign({}, glossary, meal, {
        _id: meal.id ?? meal._id,
        checked: meal.checked == null ? meal.dose != null : meal.checked,
        dose: meal.dose,
        doseUnit: meal.doseUnit,
        mealPlansId: meal.mealPlansId ?? valueId,
        mealGlossaryId: meal.mealGlossaryId,
      })
      if (data._id == null) {
        data._id = crypto.randomUUID()
      }

      return data;
    }

    if (glossary._id == null) {
      glossary._id = crypto.randomUUID()
    }

    return glossary
  })

  for (let i = 0; i < data.length; i++) {
    let item = data[i];
    let index = result.findIndex((it) => it.name === item.foodGroup)

    if (index === -1) {
      result.push({ name: item.foodGroup, items: [item] })
    } else {
      let menuIndex = result[index]?.items.findIndex(
          (it) => it.foodIngredient === item.foodIngredient
      )
      if (menuIndex === -1) {
        result[index].items.push(item)
      } else {
        result[index].items[menuIndex] = item
      }
    }
  }

  // let delta = Date.now()
  return Promise.resolve({result, delta});
})
let onChange = greenlet(function _onChange({$event, meals}) {
  let _meals = Array.from(meals);

  for (let i = 0; i < $event.length; i++) {
    let meal = $event[i];
    let index = _meals.findIndex((it) => it._id === meal._id)

    if (index >= 0 && _meals[index].checked === true && meal.checked === false) {
      _meals.splice(index, 1)
    }

    if (index < 0 && meal.checked) {
      _meals.push(meal)
    } else {
      _meals[index] = Object.assign({}, _meals[index], meal)
    }
  }

  return Promise.resolve(_meals)
})

export default {
  name: "EditMealPlanForm",
  props: ["category", "value"],
  components: {
    FormCardMakanan,
  },
  data() {
    return {
      timeStart: this.value?.timeStart,
      timeEnd: this.value?.timeEnd,
      caloriesNeed: this.value?.caloriesNeed?.toString(),
      totalCalories: this.value?.totalCalories?.toString(),
      totalPortion: this.value?.totalPortion,
      meals: this.value?.meals ?? [],
      meals_: [],
      portion: this.value?.portion ?? [],
      mealsDataDelta_: null,
    }
  },
  computed: {
    ...mapState({
      glossaries_: (s) => [...s.glossary.meals],
      asupanGizi: (s) => s.mealPlan.asupanGizi,
    }),
    id() {
      return this.value?.id;
    },
    energyIntakeNeeds() {
      return parseFloat(this.asupanGizi?.energyIntakeNeeds ?? 0)
    },
    jumlahKalori() {
      return parseFloat(this.caloriesNeed) * parseFloat(this.energyIntakeNeeds)
    },
    jumlahKaloriPercent() {
      return this.jumlahKalori / 100
    },
    mealsData() {
      return [this.meals, this.glossaries_, this.value?.id]
    },
    totalCalories_() {
      return parseFloat(this.jumlahKalori ?? "0");
    },
    caloriesNeed_() {
      return parseFloat(this.caloriesNeed ?? "0");
    },
    values_: _.throttle(function() {
      let meals = this.meals_.flatMap((it) => it.items)

      return {
        ...this.value,
        category: this.category,
        timeStart: this.timeStart,
        timeEnd: this.timeEnd,
        caloriesNeed: this.caloriesNeed_,
        totalCalories: this.totalCalories_,
        totalPortion: this.totalPortion ?? 1,
        portion: this.portion,
        meals,
      }
    }, 100),
  },
  watch: {
    mealsData: {
      immediate: true,
      deep: true,
      async handler(val, old) {
        if (equal(val, old)) return;

        let [_, __, valueId] = val
        // if (valueId == null) return;

        let delta = Date.now()
        let res = await mealsDataHandler({val, delta})

        if (this.mealsDataDelta_ == null || delta > this.mealsDataDelta_) {
          this.mealsDataDelta_ = delta;
          this.meals_ = res.result;
        }
      },
    },
    value: {
      immediate: true,
      deep: true,
      handler(value, old) {
        if (equal(value, old)) return
        if (value == null) return

        this.timeStart = value.timeStart
        this.timeEnd = value.timeEnd
        this.caloriesNeed = "" + value.caloriesNeed
        this.totalCalories = value.totalCalories
        this.totalPortion = value.totalPortion

        if (value.meals?.length !== 0) {
          this.meals = value.meals ?? []
        }
      },
    },
    values_: {
      deep: true,
      handler: _.throttle(function (value, old) {
        if (equal(value, old)) return

        this.$emit("input", value)
      }, 300),
    },
    totalCalories_: {
      immediate: true,
      handler(v) {
        if (this.totalCalories !== v) {
          this.totalCalories = v
        }
      },
    },
  },
  methods: {
    onPortionChangedFor(foodGroup, portion) {
      let index = this.portion.findIndex((it) => it.foodGroup === foodGroup)
      if (index === -1) {
        this.portion.push({
          foodGroup,
          dose: portion.dose,
          doseUnit: portion.doseUnit,
        })
      } else {
        this.portion[index].dose = portion.dose
        this.portion[index].doseUnit = portion.doseUnit
      }
    },
    onChange($event) {
      console.log('@change', $event)
      onChange({
        $event,
        meals: this.meals,
      }).then((v) => {
        console.log('done', v);
        this.meals = v;
      });
    },
    _onChange($event) {
      for (let meal of $event) {
        let index = this.meals.findIndex((it) => it._id === meal._id)

        if (index >= 0 && this.meals[index].checked === true && meal.checked === false) {
          this.$logJson('meal got unchecked', meal)
          // remove item from this.meals based on found index
          this.meals.splice(index, 1)
        }

        if (index < 0 && meal.checked) {
          this.$logJson('new meal added', meal)
          this.meals.push(meal)
        } else {
          this.$set(this.meals, index, {
            ...this.meals[index],
            ...meal,
          })
          // this.$set(this.meals_, index, {
          //   ...this.meals_[index],
          //   ...meal,
          // })
        }
      }
    },
    getPortionFor(foodGroup) {
      return this.portion.find((it) => it.foodGroup === foodGroup)
    },
  },
}

</script>
