<template>
  <c-box
    m="0"
    p="0"
    w="72px"
    h="auto"
    flex-dir="column"
    align-items="center"
    font-weight="initial"
  >
    <c-button
      variant="ghost"
      p="0"
      m="0"
      w="auto"
      h="auto"
      min-w="0"
      pos="relative"
      @click="checked = !checked"
      :is-invalid="$v.$invalid"
      :border-width="$v.$invalid ? '2px' : undefined"
      :border-color="$v.$invalid ? '#e66673' : undefined"
    >
      <c-image
        :src="photoURL"
        :alt="value.foodIngredient"
        h="72px"
        w="72px"
        object-fit="cover"
        box-shadow="0px 0px 8px 5px rgba(0, 0, 0, 0.12)"
        border-radius="8px"
        :opacity="checked ? 1 : 0.7"
      />
      <c-flex
        pos="absolute"
        align-items="center"
        justify-content="center"
        h="24px"
        w="24px"
        top="5px"
        right="5px"
        :bg="checked ? 'brand.900' : 'blackAlpha.400'"
        p="5px"
        border-radius="8px"
      >
        <c-icon name="check" size="14px" color="white" />
      </c-flex>
    </c-button>
    <c-text m="0" mt="5px" font-size="12px" text-align="center" color="#333333">
      {{ value.foodIngredient }}
    </c-text>
    <c-flex>
      <c-input
        type="number"
        name="dose"
        v-model.number="dose"
        p="0"
        font-size="12px"
        text-align="center"
        h="25px"
        :is-disabled="!checked"
        :is-invalid="$v.dose.$invalid"
      />
      <c-input
        type="text"
        name="unit"
        v-model="doseUnit"
        p="0"
        font-size="12px"
        text-align="center"
        h="25px"
        :is-disabled="!checked"
        :is-invalid="$v.doseUnit.$invalid"
      />
    </c-flex>
  </c-box>
</template>

<script>
import _ from "lodash";
import equal from "fast-deep-equal";
import requiredIf from "vuelidate/lib/validators/requiredIf";
import decimal from "vuelidate/lib/validators/decimal";

export default {
  name: "FormCardMakananItem",
  props: ["value"],
  data() {
    return {
      checked: this.value?.checked,
      dose: this.value?.dose,
      doseUnit: this.value?.doseUnit,
    };
  },
  computed: {
    photoURL() {
      return (
        this.value?.photoUrl ??
        this.value?.foodIngredientPhoto ??
        "https://via.placeholder.com/10"
      );
    },
    values_() {
      return {
        ...this.value,
        checked: this.checked,
        dose: this.dose,
        doseUnit: this.doseUnit,
      };
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(value, old) {
        if (equal(value, old)) return;

        this.dose = value?.dose;
        this.doseUnit = value?.doseUnit;
        this.checked = value?.checked ?? false;
      },
    },
    values_: {
      deep: true,
      handler(value, old) {
        if (equal(value, old)) return;

        this.$emit("input", value);
      },
    },
  },
  validations: {
    dose: { required: requiredIf((it) => it.checked), decimal },
    doseUnit: { required: requiredIf((it) => it.checked) },
  },
};
</script>
