<template>
  <c-flex flex="1" flex-dir="column" overflow="auto" pb="30px">
    <c-box px="40px" py="40px">
      <c-flex justifyContent="space-between">
        <c-heading
            color="black.900"
            fontSize="24px"
            fontWeight="700"
            marginBottom="40px"
        >
          Rencana makan
        </c-heading>
        <c-button
            backgroundColor="brand.900"
            fontSize="16px"
            fontWeight="700"
            fontFamily="Roboto"
            px="20px"
            borderRadius="100px"
            color="white"
            @click="trySubmit()"
            :is-disabled="isInvalid"
            :disabled="isInvalid"
        >
          <c-image
              :src="require('@/assets/save-icon.svg')"
              alt="image"
              marginRight="8px"
          />
          Simpan
        </c-button>
      </c-flex>
      <c-tabs marginTop="30px" variant="soft-rounded" variant-color="green">
        <c-tab-list
            v-chakra="{
            button: {
              color: 'lightGray.900',
              fontWeight: '700',
              mb: '10px',
              align: 'center',
              pos: 'relative',
              h: '8px',
              w: '100%',
              maxW: '156px',
              borderRadius: '20px',
              backgroundColor: 'superLightGray.900',
              py: '0',
              px: '0',
              marginRight: '10px',
              fontSize: '16px',
              lineHeight: '24px',
            },
            'button[aria-selected=true]': {
              backgroundColor: 'brand.900',
              color: 'brand.900',
            },
          }"
        >
          <c-tab bg="blue">
            <c-text marginTop="-42px">Sarapan</c-text>
          </c-tab>
          <c-tab>
            <c-text marginTop="-42px">Snack Pagi</c-text>
          </c-tab>
          <c-tab>
            <c-text marginTop="-42px">Makan Siang</c-text>
          </c-tab>
          <c-tab>
            <c-text marginTop="-42px">Snack Siang</c-text>
          </c-tab>
          <c-tab>
            <c-text marginTop="-42px">Makan Malam</c-text>
          </c-tab>
          <c-tab>
            <c-text mt="-42px">Snack Malam</c-text>
          </c-tab>
        </c-tab-list>

        <c-tab-panels>
          <c-tab-panel data-id="breakfast">
            <EditMealPlanForm
                ref="breakfastForm"
                key="breakfast"
                category="breakfast"
                @submit="trySubmit()"
                :value="rencanaMakan_.breakfast"
            />
          </c-tab-panel>
          <c-tab-panel data-id="morning_snack">
            <EditMealPlanForm
                ref="morningSnackForm"
                key="morning_snack"
                category="morning_snack"
                @submit="trySubmit()"
                :value="rencanaMakan_.morning_snack"
            />
          </c-tab-panel>
          <c-tab-panel data-id="lunch">
            <EditMealPlanForm
                ref="lunchForm"
                key="lunch"
                category="lunch"
                @submit="trySubmit()"
                :value="rencanaMakan_.lunch"
            />
          </c-tab-panel>
          <c-tab-panel data-id="afternoon_snack">
            <EditMealPlanForm
                ref="afternoonSnackForm"
                key="afternoon_snack"
                category="afternoon_snack"
                @submit="trySubmit()"
                :value="rencanaMakan_.afternoon_snack"
            />
          </c-tab-panel>
          <c-tab-panel data-id="dinner">
            <EditMealPlanForm
                ref="dinnerForm"
                key="dinner"
                category="dinner"
                @submit="trySubmit()"
                :value="rencanaMakan_.dinner"
            />
          </c-tab-panel>
          <c-tab-panel data-id="night_snack">
            <EditMealPlanForm
                ref="nightSnackForm"
                key="night_snack"
                category="night_snack"
                @submit="trySubmit()"
                :value="rencanaMakan_.night_snack"
            />
          </c-tab-panel>
        </c-tab-panels>
      </c-tabs>
    </c-box>

    <Portal to="main" v-if="showError">
      <c-alert-dialog is-open>
        <c-alert-dialog-overlay />
        <c-alert-dialog-content
            pos="fixed"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
        >
          <c-alert-dialog-body py="20px">
            {{ errorMessage }}
          </c-alert-dialog-body>
          <c-alert-dialog-footer>
            <c-button mx="5px" color="grey" @click="showError = false">
              Tidak
            </c-button>
            <c-button ml="5px" color="brand.900" @click="submit()">
              Yakin
            </c-button>
          </c-alert-dialog-footer>
        </c-alert-dialog-content>
      </c-alert-dialog>
    </Portal>
  </c-flex>
</template>

<script>
import _ from "lodash";
import {mapActions, mapGetters} from "vuex";
import EditMealPlanForm from "./forms/edit-meal-plan-form.vue";
import equal from "fast-deep-equal";
import getMenuCategory from "@/utils/get-menu-category";

export default {
  name: "MealPlanEdit",
  components: {EditMealPlanForm},
  data() {
    return {
      rencanaMakan_: {},
      errorMessage: null,
      showError: false,
      isInvalid: false,
    };
  },
  computed: {
    ...mapGetters({
      rencanaMakan: "mealPlan/rencanaMakan",
    }),
    emptyField() {
      return Object.entries(this.rencanaMakan_)
          .map(([key, value]) => {
            if (value == null) return key;
            if (Object.keys(value).length === 0) return key;
          })
          .filter((it) => it != null);
    },
    forms$() {
      // all collection of forms from current component refs
      return [
        this.$refs.breakfastForm,
        this.$refs.morningSnackForm,
        this.$refs.lunchForm,
        this.$refs.afternoonSnackForm,
        this.$refs.dinnerForm,
        this.$refs.nightSnackForm,
      ];
    },
    dataToSubmit$() {
      let update = []
      let create = []

      let forms = this.forms$.map((it) => {
        let meals = it.$refs.cardMakananForm
          .flatMap(v => v.meals)
          .filter(v => v.checked);
        let mealGroups = _.chain(meals).groupBy('foodGroup').keys().value();
        let portion = it.portion ?? [];
        mealGroups.forEach((group) => {
          let index = portion.findIndex(v => v.foodGroup === group);
          if (index === -1) {
            portion.push({
              dose: 1,
              doseUnit: 'porsi',
              foodGroup: group,
            })
          }
        })

        return {
          id: it.id,
          timeStart: it.timeStart,
          timeEnd: it.timeEnd,
          caloriesNeed: it.caloriesNeed,
          totalCalories: it.totalCalories,
          totalPortion: it.totalPortion ?? 1,
          category: it.category,
          programId: it.programId,
          portion,
          meals,
        };
      });

      for (let form of forms) {
        if (form.timeStart == null && form.timeEnd == null) continue;

        if (form.id == null) {
          create.push(form)
        } else {
          update.push(form)
        }
      }
      return { update, create }
    },
  },
  watch: {
    rencanaMakan: {
      immediate: true,
      handler(val, old) {
        if (!equal(val, old)) {
          for (let key of Object.keys(val)) {
            this.$set(this.rencanaMakan_, key, val[key]);
          }
        }
      },
    },
  },
  methods: {
    ...mapActions({
      listGlossary: "glossary/list",
      listMeals: "glossary/listMeals",
      getMealPlan: "mealPlan/list",
      createMealPlan: "mealPlan/createMealPlan",
      updateMealPlan: "mealPlan/updateMealPlan",
      getProfileGizi: "mealPlan/getProfileGizi",
      getAsupanGizi: "mealPlan/getAsupanGizi",
    }),
    trySubmit() {
      if (this.isInvalid) return;
      // return this.submit();

      // eslint-disable-next-line no-unreachable
      if (this.emptyField.length > 0) {
        let emptyField = this.emptyField.map((it) => getMenuCategory(it)).join(", ");
        this.errorMessage = `Apakah anda yakin tidak ingin mengisi semua field?`;
        this.errorMessage += ` ${emptyField} masih kosong`;
        this.showError = true;
      } else {
        this.submit();
      }
    },
    async submit() {
      this.showError = false;
      this.errorMessage = null;

      let dataToCreate = this.dataToSubmit$.create;
      let dataToUpdate = this.dataToSubmit$.update;
      console.log("@create", dataToCreate);
      console.log("@update", dataToUpdate);

      let clientId = this.clientId;
      await Promise.all([
        this.createMealPlan({ clientId, mealPlans: dataToCreate }),
        this.updateMealPlan({ clientId, mealPlans: dataToUpdate }),
      ]);
      await this.$router.push({ name: "nutri.meal-plan", params: { clientId } });
    },
  },
  async mounted() {
    this.$toast({
      title: "Loading...",
      status: "info",
      variant: "subtle",
      position: "bottom-right",
      duration: 5000,
      isClosable: false
    })

    await Promise.all([
      this.listGlossary(),
      this.listMeals(),
      this.getProfileGizi(this.clientId),
      this.getAsupanGizi(this.clientId),
      this.axios
          .get(`/v1/nutritionist/clients/${this.clientId}/latest-program`)
          .then((r) => r.data.data.id)
          .then((programId) => {
            this.programId = programId;
            return this.getMealPlan({clientId: this.clientId, programId});
          }),
    ]);

  },
  updated: _.throttle(function () {
    let formRefs = [
      this.$refs.breakfastForm,
      this.$refs.morningSnackForm,
      this.$refs.lunchForm,
      this.$refs.afternoonSnackForm,
      this.$refs.dinnerForm,
      this.$refs.nightSnackForm,
    ];

    let $invalid = formRefs
        .flatMap((it) => it.$refs.cardMakananForm ?? [])
        .flatMap((it) => it.$refs.cardMakananItemForm)
        .flatMap((it) => it.$v.$invalid)
        .some((it) => it === true);
    this.isInvalid = $invalid;
  }, 300),
};
</script>
