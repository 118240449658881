import { render, staticRenderFns } from "./edit.vue?vue&type=template&id=a947cea2&"
import script from "./edit.vue?vue&type=script&lang=js&"
export * from "./edit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CHeading: require('@chakra-ui/vue').CHeading, CImage: require('@chakra-ui/vue').CImage, CButton: require('@chakra-ui/vue').CButton, CFlex: require('@chakra-ui/vue').CFlex, CText: require('@chakra-ui/vue').CText, CTab: require('@chakra-ui/vue').CTab, CTabList: require('@chakra-ui/vue').CTabList, CTabPanel: require('@chakra-ui/vue').CTabPanel, CTabPanels: require('@chakra-ui/vue').CTabPanels, CTabs: require('@chakra-ui/vue').CTabs, CBox: require('@chakra-ui/vue').CBox, CAlertDialogOverlay: require('@chakra-ui/vue').CAlertDialogOverlay, CAlertDialogBody: require('@chakra-ui/vue').CAlertDialogBody, CAlertDialogFooter: require('@chakra-ui/vue').CAlertDialogFooter, CAlertDialogContent: require('@chakra-ui/vue').CAlertDialogContent, CAlertDialog: require('@chakra-ui/vue').CAlertDialog})
